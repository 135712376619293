import {get, post} from '@rails/request.js'
import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"
import {handlePlaidError, logPlaidEvent} from "@/controllers/helpers.js"
import {Modal} from "bootstrap"

export default class extends Controller {
    static targets = ['preLink', 'postLink']
    static values = {
        plaidEnv: String,
        redirectTo: String,
        assetGroupId: Number
    }

    async getToken() {
        const response = await get(`/plaid/link_token?plaid_env=${this.plaidEnvValue}`)
        if (response.ok && response.headers.get('content-type').includes('application/json')) {
            const data = await response.json
            return data.link_token
        } else {
            console.error('error! ', response)
        }
    }

    async createLink() {
        this.postLinkTargets.forEach(t => t.classList.remove('d-none'))
        this.preLinkTargets.forEach(t => t.classList.add('d-none'))

        const token = await this.getToken()
        const handler = Plaid.create({
            token: token,
            onSuccess: (public_token, metadata) => {
                this.handlePublicToken(public_token, metadata)
            },
            onLoad: () => {
                handler.open()
            },
            onExit: handlePlaidError,
            onEvent: logPlaidEvent
            // required for OAuth on mobile applications, not needed for browser-based applications
            // receivedRedirectUri: window.location.href,
        })
    }

    async handlePublicToken(public_token, metadata) {
        const link_session_id = metadata.link_session_id
        const institution_id = metadata.institution.institution_id
        const asset_group_id = this.assetGroupIdValue || null
        const accounts = metadata.accounts.map((a) => {
            return {name: a.name, mask: a.mask}
        })

        const body = JSON.stringify({
            from: window.location.href,
            link_session_id,
            institution_id,
            asset_group_id,
            accounts,
            public_token,
            plaid_env: this.plaidEnvValue
        })

        const response = await post('/plaid/exchange_public_token', { body })
        if (response.ok) {
            if (response.headers.get('content-type').includes('text/vnd.turbo-stream.html')) {
                // this institution has already been linked
                this.postLinkTargets.forEach(t => t.classList.add('d-none'))
                this.preLinkTargets.forEach(t => t.classList.remove('d-none'))
            } else {
                const data = await response.json
                let url = `/onboarding/fetch_transactions/${data.item.id}`
                if (this.redirectToValue) {
                    url += `?redirect_to=${this.redirectToValue}`
                }
                Turbo.visit(url)
            }
        } else {
            console.error('error! ', response)
        }
    }

    updateAccounts(event) {
        const assetGroupId = event.target.dataset.assetGroupId
        const selector = `.update-link-button[data-plaid-item-id="${event.target.dataset.plaidItemId}"]`
        document
          .querySelector(selector)
          .dispatchEvent(new CustomEvent('click', { detail: { assetGroupId } }))
    }
}
