import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "charactersRemaining", "message", "submit"]

    connect() {
        this.maxCharacters = parseInt(this.element.dataset.maxCharacters)
        this.onUpdate()
    }

    onUpdate() {
        const charactersUsed = this.inputTarget.value.length
        const charactersRemaining = this.maxCharacters - charactersUsed
        this.charactersRemainingTarget.textContent = charactersRemaining
        this.messageTarget.classList.toggle('text-danger', charactersRemaining < 0)
        this.submitTarget.disabled = charactersRemaining < 0
    }
}