import {Controller} from "@hotwired/stimulus"
import {post, patch} from "@rails/request.js";

export default class extends Controller {
  static targets = [
    'nameInput',
    'active',
    'form',
    'submit',
    'cancel',
    'preSave',
    'postSave',
    'postSaveDisplay'
  ]

  async initialize() {
  }

  async connect() {
    if (this.element.dataset.isNew === 'true') {
      this.editName()
    }
  }
  
  cancel() {
    this.hideForm()
  }

  resetForm() {
    if (this.hasNameInputTarget) {
      this.nameInputTarget.value = this.nameInputTarget.dataset.originalValue || ''
    }
  }

  hideForm() {
    this.resetForm()
    setTimeout(() => {
      this.element.classList.remove('editing')
      this.activeTarget.classList.remove('active')
    }, 0)
  }

  showNameInput(force = false) {
    let showInput = force
    if (!showInput) {
      showInput = this.hasNameInputTarget && this.nameInputTarget.value !== ''
    }

    if (this.hasNameInputTarget) {
      this.nameInputTarget.parentElement.classList.toggle('d-none', !showInput)
    }
  }

  editName(event) {
    if (event && event.key !== 'Tab') {
      event?.preventDefault()
    }
    this.element.classList.add('editing')
    this.activeTarget.classList.add('active')
    this.showNameInput(true)
    if (this.hasNameInputTarget) {
      this.nameInputTarget.focus()
      this.nameInputTarget.select()
    }
  }

  async submit(event) {
    let hasChanges = false
    let name = ''

    if (this.hasNameInputTarget) {
      name = this.nameInputTarget.value
      hasChanges = name !== (this.nameInputTarget.dataset.originalValue || '')
    }

    if (!hasChanges) {
      this.hideForm()
      return
    }

    const form = this.formTarget
    const body = {asset_group: {name}}
    this.hideForm()
    if (this.isCreate()) {
      this.editName(event)
    }

    this.preSaveTarget.classList.add('d-none')
    this.postSaveDisplayTarget.textContent = name
    this.postSaveTarget.classList.remove('d-none')

    const method = this.isCreate() ? post : patch
    method(form.action, {
      body, headers: {Accept: 'text/vnd.turbo-stream.html'}
    })
  }

  async onEnter(event) {
    event.preventDefault()
    this.submit(event)
  }

  onClick(event) {
    const el = event.target

    if (!(this.element.contains(el) || el === this.element)) {
      this.submit()
    }
  }

  isCreate() {
    return this.element.id === 'asset_group_header_form'
  }
}