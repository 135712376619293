import {Controller} from "@hotwired/stimulus"
import {post} from '@rails/request.js'

const ARCHIVED_ICON_CLASS = {
    true: 'fa-arrow-rotate-left',
    false: 'fa-xmark'
}

export default class extends Controller {
    static values = {
        itemId: Number,
        redirectTo: String
    }
    static targets = ['fetchTransactions', 'fetchRecurringTransactions', 'step', 'errorMessage', 'spinner', 'body']

    async connect() {
        this.fetchHistory()
    }
    async fetchHistory() {
        this.fetchTransactionsTarget.querySelector('button').click()
    }

    async fetchRecurringTransactions() {
        this.fetchRecurringTransactionsTarget.querySelector('button').click()
    }

    showError() {
        this.errorMessageTarget.classList.remove('d-none')
        if (this.hasSpinnerTarget) {
            this.spinnerTarget.classList.add('d-none')
        }
        this.bodyTarget.classList.add('d-none')
    }

    next() {
        const archived_ids = Array.from(this.element.querySelectorAll(
            '.recurring-transaction.archived'
        )).map(rt => rt.dataset.id)

        const unarchived_ids = Array.from(this.element.querySelectorAll(
            '.recurring-transaction:not(.archived)'
        )).map(rt => rt.dataset.id)

        post(
            `/onboarding/confirm_inflows/${this.itemIdValue}`,
            { body: { archived_ids, unarchived_ids} }
        ).then((response) => {
            if (response.ok) {
                const currentStep = this.stepTargets.find(
                    t => t.classList.contains('rt-active')
                )
                const nextSteps = this.stepTargets.filter(
                    t => t.classList.contains('rt-pending')
                )
                if (nextSteps.length) {
                    currentStep.classList.replace('rt-active', 'rt-completed')
                    let nextStep = nextSteps[0]
                    nextStep.classList.replace('rt-pending', 'rt-active')
                } else {
                    Turbo.visit(decodeURIComponent(this.redirectToValue) || '/accounts')
                }
            } else {
                console.error('error!', response)
                this.showError()
            }
        })
    }

    assumeArchive(event) {
        if (event.target.classList.contains('pending')) {
            return
        }

        event.target.classList.add('pending')
        event.target.parentElement.classList.add('pending')
        
        let isAlreadyArchived = event.target.classList.contains(ARCHIVED_ICON_CLASS[true])
        let willBeArchived = !isAlreadyArchived

        event.target.classList.replace(ARCHIVED_ICON_CLASS[isAlreadyArchived], ARCHIVED_ICON_CLASS[willBeArchived])
        event.target.parentElement.classList.toggle('archived', willBeArchived)
    }
}
