import {Controller} from "@hotwired/stimulus"
import { Picker } from 'emoji-mart'

export default class extends Controller {
    static targets = ['input', 'container']

    connect() {
        this.ignoreOneOutsideClick = false
    }

    disconnect() {
        this.picker = null
        this.ignoreOneOutsideClick = false
    }

    open(e) {
        if (e.target.tagName === 'EM-EMOJI-PICKER') {
            return
        }

        if (this.openExistingPicker()) {
            return
        }

        this.createPicker()
    }

    openExistingPicker() {
        if (this.picker) {
            this.ignoreOneOutsideClick = true
            this.picker.classList.remove('d-none')
            return true
        }
    }

    createPicker() {
        this.picker = new Picker({
            theme: 'light',
            autoFocus: true,
            onEmojiSelect: this.onEmojiSelect.bind(this),
            onClickOutside: this.onClickOutside.bind(this)
        });
        this.containerTarget.appendChild(this.picker)
    }

    onEmojiSelect(emoji) {
        this.inputTarget.value = emoji.native
        this.inputTarget.dispatchEvent(new Event('change'))
        this.picker.classList.add('d-none')
        this.ignoreOneOutsideClick = false
    }

    onClickOutside() {
        if (this.ignoreOneOutsideClick) {
            this.ignoreOneOutsideClick = false
        } else {
            this.picker.classList.add('d-none')
        }
    }
}