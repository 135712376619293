import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['emoji', 'rectangle', 'name']

  updateEmoji(event) {
    this.emojiTarget.textContent = event.target.value
  }

  updateColor(event) {
    this.rectangleTarget.style.backgroundColor = event.target.value
  }

  updateName(event) {
    this.nameTarget.textContent = event.target.value
  }
}