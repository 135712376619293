import {Controller} from "@hotwired/stimulus"
import {Modal} from 'bootstrap'
import {post} from '@rails/request.js'

export default class extends Controller {
    static targets = ['preConsent', 'postConsent']
    static values = { showModalImmediately: Number }

    connect() {
        this.pending = true
        this.render()
        if (this.showModalImmediatelyValue === 0) {
          this.revealModal()
        }
    }

    disconnect() {
        if (this.modal) {
            this.modal.dispose()
            this.modal = null
        }
    }

    render() {
        if (this.hasPreConsentTarget) {
            const consented = this.consented()
            this.preConsentTarget.classList.toggle('d-none', consented)
            if (this.hasPostConsentTarget) {
                this.postConsentTarget.classList.toggle('d-none', !consented)
            }
        }
    }

    consented() {
        return this.element.dataset.consented === 'true'
    }

    async onDismiss() {
        if (this.consented() && this.pending) {
            const url = this.element.dataset.deferUrl
            const response = await post(url, {body: this.element.dataset.deferParams})
            if (!response.ok) {
                console.error('error!')
            }
        }
    }

    hidePendingInvite() {
        document.getElementById('pending-invite-container')?.remove()
    }

    revealModal() {
        this.modal = new Modal(this.element)
        this.modal.show()
        this.pending = true
    }

    hideModal() {
        this.pending = false
        if (this.modal) {
            this.modal.hide()
        }
    }

    async consent(event) {
        this.element.dataset.consented = 'true'
        this.render()

        const url = event.target.dataset.url
        const response = await post(url, {body: event.target.dataset.params})
        if (!response.ok) {
            console.error('error!')
        }
    }

    async decline(event) {
        const url = event.target.dataset.url
        const response = await post(url, {body: event.target.dataset.params})
        if (response.ok) {
            this.hideModal()
            this.hidePendingInvite()
        }
    }
}