import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"

export default class extends Controller {
    static targets = ['checkbox', 'selectedCount', 'identifyMultiple']

    connect() {
        this.updateSelectedCount()
    }

    selectAll(event) {
        this.checkboxTargets.forEach(checkbox => {
            checkbox.checked = event.target.checked
        })

        this.updateSelectedCount()
    }

    updateSelectedCount() {
        const count = this.getSelected().length;
        this.selectedCountTarget.textContent = count

        if (count === 0) {
            this.identifyMultipleTarget.setAttribute("disabled", true)
        } else {
            this.identifyMultipleTarget.removeAttribute("disabled")
        }
    }
    
    getSelected() {
        return this.checkboxTargets.filter(c => c.checked)
    }

    identifyMultipleAsRecurring(event) {
        event.preventDefault()

        this.checkboxTargets.forEach(c => {
            if (c.classList.contains('recurring-disabled')) {
                c.checked = false
            }
        })

        const selectedTransactions = this.getSelected().map(c => c.value)
        if (selectedTransactions.length == 0) {
            return
        }
        let url = new URL(event.target.href)
        url.searchParams.append("plaid_transaction_ids", selectedTransactions.join(','))
        url.searchParams.append("redirect_to", window.location.href)
        Turbo.visit(url.toString())
    }

    updateUrl(event) {
        if (event.detail.success) {
            let url = new URL(window.location)

            let form = event.target
            let formData = new FormData(form)

            for (let [key, value] of formData.entries()) {
                url.searchParams.set(key, value)
            }

            window.history.pushState({}, "", url)
        }
    }
}