import {post} from "@rails/request.js";

export function arrayEquals(a, b) {
    return JSON.stringify(a) === JSON.stringify(b)
}

export function debounce(callback, time, startDebounceCallback) {
    let debounceTimer = null;

    function result(...args) {
        if (startDebounceCallback) {
            startDebounceCallback.apply()
        }
        window.clearTimeout(debounceTimer)
        debounceTimer = window.setTimeout(() => {
            callback.apply(this, args)
        }, time)
    }

    return result;
}

export function throttle(callback, time) {
    let throttling = false;

    return function (...args) {
        if (!throttling) {
            throttling = true
            callback.apply(this, args)

            setTimeout(() => {
                throttling = false
            }, time);
        }
    }
}

export function loadScript(url) {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.src = url
        script.async = true
        script.onload = resolve
        script.onerror = reject
        document.head.appendChild(script)
    });
}

export function addUrlParam(url, name, value) {
    url = new URL(url)
    const params = new URLSearchParams(url.search)
    params.set(name, value)
    url.search = params.toString()

    return url.toString()
}

export function addUrlParamToCurrentUrl(name, value) {
    return addUrlParam(document.location.href, name, value)
}

export function handlePlaidError(err, _metadata) {
    // The user exited the Link flow.
    if (err != null) {
        // The user encountered a Plaid API error prior
        // to exiting.
        window.location.href = addUrlParamToCurrentUrl('flash', 'link_error')
    }
}

export async function logPlaidEvent(eventName, metadata) {
    const body = JSON.stringify({plaid_link_event: {name: eventName, metadata}})
    const response = await post('/link_events', {body})
    if (!response.ok) {
        console.error('error! ', response)
    }
}

export function preventClickPropagation(event) {
    if (event?.type === 'click') {
      event.stopPropagation()
    }
}
