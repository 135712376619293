import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['message']

    connect() {
        this.timeouts = []
        this.timeouts.push(setTimeout(() => {
            this.messageTargets.forEach(t => t.style.maxHeight = t.offsetHeight + 'px')
        }, 3000))

        let disappearTimeout = 5500

        if (this.element.classList.contains('has-link')) {
            disappearTimeout = 11000
        }

        if (this.element.classList.contains('timedout')) {
            disappearTimeout = 65500
        }

        this.timeouts.push(setTimeout(() => {
            this.messageTargets.forEach(message => message?.remove())
        }, disappearTimeout))
    }

    disconnect() {
        this.timeouts.forEach(t => clearTimeout(t))
    }
}