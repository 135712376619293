import {Controller} from "@hotwired/stimulus"
import {debounce} from './helpers'

export default class extends Controller {
  initialize() {
    this.debouncedSubmit = debounce(this.submit.bind(this), 500)
  }

  debouncedSubmit() { }

  async submit(event) {
    event.target.closest('form').requestSubmit()
  }
}