import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'nameDisplay',
    'valueDisplay',
    'nameWhenBlank',
    'nameWhenPresent',
    'valueWhenBlank',
    'valueWhenPresent',
  ]

  onUpdate(event) {
    const { name, value } = event.detail
    this.element.classList.remove('d-none')

    this.nameDisplayTarget.textContent = name
    this.valueDisplayTarget.textContent = value

    const isNameBlank = name === ''
    const isValueBlank = value === ''

    this.nameWhenBlankTarget.classList.toggle('d-none', !isNameBlank)
    this.nameWhenPresentTarget.classList.toggle('d-none', isNameBlank)
    this.valueWhenBlankTarget.classList.toggle('d-none', !isValueBlank)
    this.valueWhenPresentTarget.classList.toggle('d-none', isValueBlank)
  }
}