import {Controller} from "@hotwired/stimulus"
import {debounce} from './helpers'

export default class extends Controller {
    initialize() {
        this.format = debounce(this.format, 500)
    }

    connect() {
        this.format()
    }

    format() {
        const rawValue = this.element.value
        if (rawValue.match(/(\d\D*){10}/) && !rawValue.match(/[a-z@.]/i)) {
            this.element.value = this.formatPhoneNumber(rawValue)
        }
    }

    formatPhoneNumber(value) {
        if (typeof value !== 'string') {
            return value
        }
        const cleanedInput = value.replace(/[^0-9]/g, '')
        const match = cleanedInput.match(/^(\d{3})(\d{3})(\d{4})$/)

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`
        }

        // Return the original input if it doesn't match the pattern
        return value
    }
}