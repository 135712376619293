import {Controller} from "@hotwired/stimulus"
import moment from 'moment'
export default class extends Controller {
    static targets = ['input', 'message']

    connect() {
        this.updateMessage()
    }

    updateMessage() {
        const currentDate = moment().startOf('day')
        const futureDate = moment(this.inputTarget.value)
        let delta = futureDate.diff(currentDate, 'days');
        let absoluteDelta = Math.abs(delta)
        let unit = absoluteDelta === 1 ? 'day' : 'days'

        if (absoluteDelta > 75) {
            absoluteDelta = Math.abs(futureDate.diff(currentDate, 'weeks'))
            unit = 'weeks'

            if (absoluteDelta > 17) {
                absoluteDelta = Math.abs(futureDate.diff(currentDate, 'months'))
                unit = 'months'

                if (absoluteDelta > 36) {
                    absoluteDelta = Math.abs(futureDate.diff(currentDate, 'years'))
                    unit = 'years'
                }
            }
        }

        if (delta < 0) {
            this.messageTarget.innerText = `about ${absoluteDelta} ${unit} ago`
        } else {
            this.messageTarget.innerText = `in about ${absoluteDelta} ${unit}`
        }
    }
}