import {Controller} from "@hotwired/stimulus"
import {Loader} from "@googlemaps/js-api-loader"

window.googleMapsPromises = []
window.isGoogleMapsLoading = false
window.isGoogleMapsLoaded = false
window.Place = null

async function loadGoogleMaps(apiKey) {
    if (window.isGoogleMapsLoaded) {
        return
    }

    if (!window.isGoogleMapsLoading) {
        window.isGoogleMapsLoading = true
        const loader = new Loader({
            apiKey: apiKey,
            version: 'weekly',
            libraries: ['places'],
            callback: 'initGoogleMaps',
            language: 'en',
            region: 'US'
        })
        loader.importLibrary('places').then(({Place}) => {
            window.Place = Place
            window.isGoogleMapsLoaded = true
            window.googleMapsPromises.forEach(promise => {
                promise.resolve()
            })
            window.googleMapsPromises = []
        })
    }

    let promiseResolve = null
    const promise = new Promise((resolve, _reject) => {
        promiseResolve = resolve
    })
    window.googleMapsPromises.push({resolve: promiseResolve})
    return promise

}

export default class extends Controller {
    static targets = [
        'line1',
        'line2',
        'city',
        'state',
        'postalCode',
        'longCountry',
        'longCountryHelpText',
        'shortCountry'
    ]

    async initialize() {
        await loadGoogleMaps(this.element.dataset.apiKey)
    }

    async connect() {
        await loadGoogleMaps(this.element.dataset.apiKey)
        this.autocomplete = new google.maps.places.Autocomplete(this.line1Target, {
            componentRestrictions: {country: ['us']},
            fields: ['address_components', 'geometry'],
            types: ['address'],
        })
        this.autocomplete.addListener('place_changed', this.onChange.bind(this))
    }

    onChange() {
        const place = this.extractComponents(this.autocomplete.getPlace())

        this.line1Target.value = place.line1
        this.cityTarget.value = place.locality || place.administrative_area_level_3 || place.administrative_area_level_2
        this.stateTarget.value = place.state
        this.postalCodeTarget.value = place.postal_code
        this.longCountryTarget.value = place.longCountry
        this.shortCountryTarget.value = place.shortCountry
        this.line2Target.focus()
    }

    onCountryChanged(event) {
        const isUSA = event.target.value === 'United States';
        this.longCountryHelpTextTarget.classList.toggle('d-none', isUSA)
        this.shortCountryTarget.value = isUSA ? 'US' : this.longCountryHelpTextTarget.value
    }

    extractComponents(place) {
        let line1 = ''
        let postal_code = ''
        let locality = ''
        let state = ''
        let longCountry = ''
        let shortCountry = ''
        let administrative_area_level_2 = ''
        let administrative_area_level_3 = ''

        for (const component of place.address_components) {
            const componentType = component.types[0]

            switch (componentType) {
                case "street_number": {
                    line1 = `${component.long_name} ${line1}`
                    break
                }

                case "route": {
                    line1 += component.short_name
                    break
                }

                case "postal_code": {
                    postal_code = `${component.long_name}${postal_code}`
                    break
                }

                case "postal_code_suffix": {
                    // Let's just ignore the suffix for now
                    // postal_code = `${postal_code}-${component.long_name}`
                    break
                }
                case "locality":
                    locality = component.long_name
                    break
                case "administrative_area_level_1": {
                    state = component.short_name
                    break
                }
                case "administrative_area_level_2": {
                    administrative_area_level_2 = component.short_name
                    break
                }
                case "administrative_area_level_3": {
                    administrative_area_level_3 = component.short_name
                    break
                }
                case "country":
                    longCountry = component.long_name
                    shortCountry = component.short_name
                    break
            }
        }
        
        return {
            line1,
            postal_code,
            locality,
            state,
            longCountry,
            shortCountry,
            administrative_area_level_2,
            administrative_area_level_3,
        }
    }
}