import {Controller} from "@hotwired/stimulus";
import {preventClickPropagation} from "@/controllers/helpers.js";
import {post} from "@rails/request.js";

export default class extends Controller {
  toggleCollapse(event) {
    if (event.target.closest('[data-prevent-expand-collapse="true"]')) {
      return
    }
    preventClickPropagation(event)
    this.element.classList.toggle('collapsed')
  }

  async onAssetSorted(event) {
    const id = parseInt(event.detail.item.dataset.assetId)
    const path = event.detail.item.dataset.sortPath
    if (!['financial_assets', 'plaid_account_assets'].includes(path)) {
      return
    }
    const from_asset_group_id = event.detail.from.dataset.assetGroupId
    const to_asset_group_id = event.detail.to.dataset.assetGroupId
    const to_sort_order = event.detail.newDraggableIndex

    const body = JSON.stringify({
      from_asset_group_id, to_asset_group_id, to_sort_order
    })
    await post(`/${path}/${id}/reorder`, { body })
  }
}