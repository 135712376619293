 import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.updateElementsRequiringBadge()
    }

    disconnect() {
        this.updateElementsRequiringBadge()
    }

    updateElementsRequiringBadge() {
        const count = document
            .querySelectorAll('[data-show-recaptcha-badge="true"]')
            .length

        const hideBadge = count === 0

        document
            .querySelector('body')
            .classList
            .toggle('hide-recaptcha-badge', hideBadge)
    }
}