import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { stopAll: Boolean }

  connect() {
    if (this.stopAllValue) {
      this.stopAll()
    }
  }

  disconnect() {
    if (this.stopAllValue) {
      this.removeAll()
    }
  }

  removeAll() {
    this.element.removeEventListener('mousedown', this.stopPropagation)
    this.element.removeEventListener('mouseup', this.stopPropagation)
    this.element.removeEventListener('click', this.stopPropagation)
    this.element.removeEventListener('dblclick', this.stopPropagation)
    this.element.removeEventListener('mouseenter', this.stopPropagation)
    this.element.removeEventListener('mouseleave', this.stopPropagation)
  }

  stopAll() {
    this.element.addEventListener('mousedown', this.stopPropagation)
    this.element.addEventListener('mouseup', this.stopPropagation)
    this.element.addEventListener('click', this.stopPropagation)
    this.element.addEventListener('dblclick', this.stopPropagation)
    this.element.addEventListener('mouseenter', this.stopPropagation)
    this.element.addEventListener('mouseleave', this.stopPropagation)
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  preventDefault(event) {
    event.preventDefault()
  }
}