import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"

export default class extends Controller {
    connect() {
        this.element.classList.add('cursor-pointer')
    }

    visit({ params: { url } }) {
        Turbo.visit(url)
    }
}
