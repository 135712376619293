import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['emoji', 'circle']

    onInput(event) {
        event.preventDefault()
        event.stopPropagation()
        this.emojiTarget.textContent = event.target.value = this.removeNonEmoji(event.target.value)
    }

    updateEmoji(event) {
        event.preventDefault()
        event.stopPropagation()
        this.emojiTarget.textContent = event.target.value = this.removeNonEmoji(event.target.value)
    }

    updateColor(event) {
        this.circleTarget.style.backgroundColor = event.target.value
    }

    removeNonEmoji(value) {
      return value.replace(/[\u0000-\u007F]/g, '')
    }
}
