import {Controller} from "@hotwired/stimulus"
import moment from 'moment'
import {Dropdown} from 'bootstrap'


export default class extends Controller {
    static targets = ['input', 'dayDropdown', 'monthDropdown', 'yearDropdown']

    connect() {
        this.date = moment(this.inputTarget.value || '2000-01-01')
        this.updateDayOptions()
    }

    selectMonth(event) {
        // moment is 0-indexed, while Rails is 1-indexed
        const month = event.target.dataset.value - 1
        this.inputTarget.value = this.date.month(month).format('YYYY-MM-DD')
        this.inputTarget.dispatchEvent(new Event('input'))
        this.monthDropdownTarget.querySelector('input').value = this.date.format('MMMM')
        this.updateDayOptions()
        this.dayDropdownTarget.querySelector('input').focus()
    }

    selectDay(event) {
        const day = event.target.dataset.value
        this.inputTarget.value = this.date.date(day).format('YYYY-MM-DD')
        this.inputTarget.dispatchEvent(new Event('input'))
        this.dayDropdownTarget.querySelector('input').value = day
        this.yearDropdownTarget.querySelector('input').focus()
    }

    selectYear(event) {
        const year = event.target.dataset.value
        this.inputTarget.value = this.date.year(year).format('YYYY-MM-DD')
        this.inputTarget.dispatchEvent(new Event('input'))
        this.yearDropdownTarget.querySelector('input').value = year
        this.updateDayOptions()
        this.yearDropdownTarget.querySelector('input').blur()
    }

    updateDayOptions() {
        const maxDay = this.date.daysInMonth()
        this.dayDropdownTarget.querySelectorAll('.dropdown-item').forEach((opt) => {
            const day = parseInt(opt.dataset.value);
            opt.classList.toggle('hidden-option', day > maxDay)
        })

        this.dayDropdownTarget.querySelector('input').dispatchEvent(new Event('refreshVisibleOptions'))
    }
}
