import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'identifyMultipleInternal']

  connect() {
    this.getSelectedCount()
  }

  selectAll(event) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
    })

    this.getSelectedCount()
  }

  getSelectedCount() {
    const count = this.getSelected().length;

    if (count === 0) {
      this.identifyMultipleInternalTarget.children[0].setAttribute("disabled", true)
    } else {
      this.identifyMultipleInternalTarget.children[0].removeAttribute("disabled")
    }
  }

  getSelected() {
    return this.checkboxTargets.filter(c => c.checked)
  }

  async submit(event) {
    event.preventDefault()
    const selected = this.getSelected();
    const selectedTransactions = selected.map(c => c.value)
    const thresholdClasses = selected.map(c => c.closest('.table-row').querySelector('.row-block-cog').classList.contains('dropdown-top') ? 'dropdown-top' : '')
    event.detail.fetchOptions.body.append('plaid_transaction_ids', selectedTransactions.join(','))
    event.detail.fetchOptions.body.append('threshold_classes', thresholdClasses.join(','))
    event.detail.resume()
  }
}
