import {Controller} from "@hotwired/stimulus"
import Coloris from '@melloware/coloris';

const SWATCHES = [
    '#f8b4d9', // pink-300
    '#f8b4b4', // red-300
    '#fdba8c', // orange-300
    '#faca15', // yellow-300
    '#84e1bc', // green-300

    '#fce8f3', // pink-100
    '#fde8e8', // red-100
    '#feecdc', // orange-100
    '#fdf6b2', // yellow-100
    '#def7ec', // green-100


    '#f3f4f6', // gray-100
    '#edebfe', // purple-100
    '#e5edff', // indigo-100
    '#e1effe', // blue-100
    '#d5f5f6', // teal-100

    '#d1d5db', // gray-300
    '#cabffd', // purple-300
    '#b4c6fc', // indigo-300
    '#a4cafe', // blue-300
    '#7edce2', // teal-300

// '#07273f', // nav-y00
]
export default class extends Controller {
    connect() {
        Coloris.init()
        Coloris({
            theme: 'polaroid',
            swatchesOnly: true,
            swatches: SWATCHES,
            defaultColor: SWATCHES[0],
            onChange: (color, input) => {
                input.value = color
                input.dispatchEvent(new Event('change'))
            }
        })
        if (this.element.value === '') {
            const color = SWATCHES[Math.floor(Math.random() * SWATCHES.length)]
            this.element.closest('.clr-field').style.color = color
            this.element.value = color
            this.element.dispatchEvent(new Event('input'))
        }
    }
}
