import {Controller} from "@hotwired/stimulus"
import {get, post} from '@rails/request.js'
import {Modal} from 'bootstrap'
import {handlePlaidError, logPlaidEvent} from "@/controllers/helpers.js";

export default class extends Controller {
    static targets = ['refresh', 'renameModal']
    static values = {
        plaidEnv: String
    }

    connect() {
        if (this.hasRenameModalTarget) {
            this.renameModal = new Modal(this.renameModalTarget)
        }
    }

    disconnect() {
        if (this.renameModal) {
            this.renameModal.dispose()
            this.renameModal = null
        }
    }

    afterSubmit(event) {
        if (event.detail.success && this.renameModal) {
            this.renameModal.hide()
        }
    }

    async getToken(item_id, add_new_accounts) {
        const response = await get(`/plaid/${item_id}/update_link_token?add_new_accounts=${add_new_accounts}`)
        if (response.ok) {
            const data = await response.json
            return data.update_link_token
        } else {
            console.error('error! ', response)
        }
    }

    async update(event) {
        const data = event.target.dataset
        const asset_group_id = event.detail?.assetGroupId || data.assetGroupId
        const item_id = data.plaidItemId
        const add_new_accounts = true

        let parent = document
        if (asset_group_id) {
            parent = document.querySelector(`.asset-group-container[data-asset-group-id="${asset_group_id}"]`)
        }
        parent
            .querySelectorAll('[data-link-target="postLink"]')
            .forEach(t => t.classList.remove('d-none'))
        parent
            .querySelectorAll('[data-link-target="preLink"]')
            .forEach(t => t.classList.add('d-none'))

        const token = await this.getToken(item_id, add_new_accounts)
        const handler = Plaid.create({
            token: token,
            onSuccess: async (_token, metadata) => {
                this.onUpdateSuccess(item_id, metadata.link_session_id, asset_group_id)
            },
            onLoad: () => {
                handler.open()
            },
            onExit: handlePlaidError,
            onEvent: logPlaidEvent
        });
    }

    async onUpdateSuccess(item_id, link_session_id, asset_group_id) {
        const body = JSON.stringify({
            link_session_id, asset_group_id, plaid_env: this.plaidEnvValue, id: item_id
        })
        const response = await post('/plaid/refresh_item', { body })
        if (response.ok) {
            window.location.href = `/onboarding/fetch_transactions/${item_id}?reconnected=true&redirect_to=${encodeURIComponent(window.location.href)}`
        }
    }

    async reconnect(event) {
        // Initialize Link with the token parameter
        // set to the generated link_token for the Item
        const item_id = event.target.dataset.plaidItemId
        const add_new_accounts = false
        const token = await this.getToken(item_id, add_new_accounts)

        const handler = Plaid.create({
            token: token,
            onSuccess: async (_, __) => {
                await post(`/items/${event.target.dataset.plaidItemId}/reconnected`)
                this.refreshTarget.click()
            },
            onLoad: () => {
                handler.open()
            },
            onExit: handlePlaidError,
            onEvent: logPlaidEvent
        });
    }
}