import {Controller} from "@hotwired/stimulus"
import {debounce} from './helpers'
import {post, patch} from '@rails/request.js'

export default class extends Controller {
    static targets = ["pill", "pillFilter", "select", "search", "form", "results"]

    connect() {
        this.isDebouncing = false
        this.pendingSelect = false
        this.preventBlur = false
        this.isSelectingOnly = false
        this.highlightFirst()
        this.onClickOutside = this.onClickOutside.bind(this)
        window.addEventListener('click', this.onClickOutside)
        this.selectOnlyOption()
    }

    disconnect() {
        window.removeEventListener('click', this.onClickOutside)
    }

    initialize() {
        this.debouncedSearch = debounce(this.search.bind(this), 100, () => {
            this.isDebouncing = true
            this.show()
        })
    }

    debouncedSearch() {
    }

    async search() {
        this.isDebouncing = false
        const formData = this.formTarget.dataset
        formData.query = this.searchTarget.value
        await post(formData.url, {
            body: formData,
            headers: {Accept: 'text/vnd.turbo-stream.html'}
        })
        if (this.pendingSelect) {
            setTimeout(this.selectHighlighted.bind(this), 1)
        }
    }

    edit(event) {
        this.pillTarget.classList.add('d-none')
        this.pillFilterTarget.classList.remove('d-none')
        this.focus()
    }

    selectHighlighted(event = null) {
        if (event) {
            event.stopPropagation()
            event.preventDefault()
        }
        if (this.isDebouncing) { // we are waiting for search results
            this.pendingSelect = true
            return
        }

        const highlighted = this.getCategoryPills()[this.highlightedIndex]
        highlighted.click()
    }

    select(event) {
        const {name, categoryId} = event.params
        if (this.hasSelectTarget) {
            this.selectTarget.value = categoryId
            this.selectTarget.dataset.selected = categoryId
            this.selectTarget.dispatchEvent(new Event('input'))
            this.searchTarget.value = name
        }

        if (this.isSelectingOnly) {
            this.isSelectingOnly = false
        } else {
            this.updatePill(event.params)
        }

        this.preventBlur = false
        this.blur()
    }

    show() {
        this.resultsTarget.classList.remove('d-none')
        this.highlightFirst()
    }

    focus() {
        this.show()
        this.searchTarget.select()
    }

    toggle() {
        if (this.resultsTarget.classList.contains('d-none')) {
            this.focus()
        } else {
            this.blur()
        }
    }

    blur() {
        if (this.isHidden()) return

        if (this.preventBlur) {
            this.preventBlur = false
            return
        }

        this.resultsTarget.classList.add('d-none')
        if (this.hasPillTarget) {
            this.pillFilterTarget.classList.add('d-none')
            this.pillTarget.classList.remove('d-none')
        }
    }

    highlightFirst() {
        this.highlightedIndex = 0
        this.updateHighlight()
    }

    updateHighlight() {
        const categories = this.resultsTarget.querySelectorAll('.category-pill-container')

        categories.forEach((c, i) => {
            const isHighlighted = i === this.highlightedIndex
            c.classList.toggle('highlighted', isHighlighted)
            if (isHighlighted) {
                c.scrollIntoView({ behavior: 'auto', block: 'nearest' })
            }
        })
    }

    highlightUp() {
        this.highlightedIndex = Math.max(0, this.highlightedIndex - 1)
        this.updateHighlight()
    }

    highlightDown() {
        this.highlightedIndex = Math.min(this.getCategoryPills().length - 1, this.highlightedIndex + 1)
        this.updateHighlight()
    }

    getCategoryPills() {
        return this.resultsTarget.querySelectorAll('.category-pill-container')
    }

    isHidden() {
        return this.element.classList.contains('d-none');
    }

    mousedown() {
        this.preventBlur = true
    }

    onClickOutside(event) {
        if (this.isHidden()) return

        if (!(event.target === this.element || this.element.contains(event.target))) {
            this.blur(event)
        }
    }

    selectOnlyOption() {
        if (this.element.classList.contains('d-none')) {
            return
        }
        if (this.getCategoryPills().length === 1) {
            this.isSelectingOnly = true
            this.selectHighlighted()
        }
    }

    submitClosest(event) {
        event.target.closest('form').requestSubmit()
    }

    async updatePill({ categoryId, name, emoji, color }) {
        if (this.hasPillTarget) {
            this.pillTarget.querySelector('.category-pill').style = `border-color: ${color}; background-color: ${color}`
            this.pillTarget.querySelector('.the-emoji').textContent = emoji
            this.pillTarget.querySelector('.category-pill-name').textContent = name

            const params = JSON.parse(this.element.dataset.params.replace('CATEGORY_ID', categoryId))
            const body = {
                from: "category-pill",
                ...params
            }
            const response = await patch(this.element.dataset.url, { body })
            if (response.ok) {
                let form
                if ((form = document.querySelector('#category-update-refresh-form'))) {
                    // calendar specific
                    const expandedDateInput = form.querySelector('[name="calendar[expanded_date]"]')
                    if (expandedDateInput) {
                        expandedDateInput.value = this.element.dataset.date || null
                    }

                    // all
                    form.requestSubmit()
                }
            } else {
                console.error('error!')
            }
        }
    }
}