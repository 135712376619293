import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['identity', 'password']
    connect() {
        this.page = 'identity'
    }

    next(event) {
        if (this.page === 'identity') {
            event.preventDefault()
            this.page = 'password'
            this.identityTargets.forEach(t => t.classList.add('d-none'))
            this.passwordTarget.classList.remove('d-none')
            const passwordField = this.passwordTarget.querySelector('input');
            passwordField.required = 'required'
            passwordField.focus()
        }
    }
}