import {Controller} from "@hotwired/stimulus"
import moment from "moment";

export default class extends Controller {
  static targets = [ "expiresAt", "hiddenRole" ]
  static values = {
    accessExpired: Boolean
  }

  onRoleChange(event) {
    const accessExpired = moment(this.expiresAtTarget.value).startOf("day") < moment().startOf("day")
    if (accessExpired && event.target.value !== "3") {
      this.expiresAtTarget.value = ""
    }

    document.getElementById(event.target.id.replace("visible_", "")).checked = true
  }
}