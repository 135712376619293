import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['input', 'visible', 'hidden']

    connect() {
        this.hidden = true
        this.update()
    }

    hide() {
        this.hidden = true
        this.update()
    }

    reveal() {
        this.hidden = false
        this.update()
    }

    update() {
        this.visibleTarget.classList.toggle('d-none', !this.hidden)
        this.hiddenTarget.classList.toggle('d-none', this.hidden)
        this.inputTarget.type = this.hidden ? 'password' : 'text'
    }
}