import { Controller } from "@hotwired/stimulus"
import {Collapse} from 'bootstrap'

export default class extends Controller {
    static targets = ['group']

    numExpanded = 0

    show(event) {
        if (event.target.classList.contains('initializing') && !event.target.classList.contains('pre-expanded')) {
            event.preventDefault()
            return
        }
        this.numExpanded++
        this.element.classList.add('expanded')
        event.target.parentElement.querySelector('.collapsed-details').classList.add('d-none')
    }

    shown(event) {
        setTimeout(() => {
            event.target.classList.remove('pre-expanded')
        }, 100)
    }

    hide(event) {
        this.numExpanded--
        if (this.numExpanded === 0) {
            this.element.classList.remove('expanded')
        }
        setTimeout(() => {
            event.target.classList.remove('pre-expanded')
            event.target.parentElement.querySelector('.collapsed-details').classList.remove('d-none')
        }, 100)
    }

    connect() {
        this.numExpanded = 0
        this.collapses = []
        this.groupTargets.forEach(g => {
            g.classList.add('initializing')
            const collapse = new Collapse(g);
            this.collapses.push(collapse)
            g.classList.remove('initializing')
        })
    }

    disconnect() {
        this.collapses.forEach(c => c.dispose())
    }
}
