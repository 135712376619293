import { Application } from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target)
}

export { application }
