import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['maxAmount', 'inexact']

    update(event) {
        let isExact = event.target.value === 'true'
        this.inexactTargets.forEach(t => t.classList.toggle('d-none', isExact))
        if (isExact) {
            this.maxAmountTarget.value = null;
        }
    }
}