import {Controller} from "@hotwired/stimulus"
import {debounce} from "@/controllers/helpers.js";

/** Hi there! This was a challenging component to write, so test it carefully if edits are necessary.
 *
 * We use bootstrap Dropdowns for the css only. By omitting the data-bs-toggle="dropdown" we prevent the
 * dropdown js from interfering. I say interfere because bootstrap dropdowns are not set up to handle
 * a situation where you have an input inside the button. I ran into a lot of issues trying to get
 * the focus events to play nice with each other and ultimately decided it was easier to just
 * do all of the js for the dropdown on my own and just use its css. Have a great day!
 */
export default class extends Controller {
    static targets = ['input', 'dropdown', 'option', 'dropdownMenu']

    connect() {
        this.oldValue = ''
        this.highlightFirst()
        this.debouncedSelectOnly = debounce(this.selectOnly.bind(this), 150)
    }

    search(event) {
        this.show()
        const q = event.target.value.toLowerCase()

        const isFreshQuery = !this.oldValue.startsWith(q)
        const highlightedContent = this.getSelectedOption()?.textContent

        this.highlightedIndex = 0
        let didUpdateHighlight = false

        this.optionTargets.forEach(opt => {
            const matches = opt.dataset.searchable.includes(q)
            opt.classList.toggle('d-none', !matches)

            if (!didUpdateHighlight) {
                if (opt.textContent === highlightedContent) {
                    if (!matches) {
                        if (isFreshQuery) {
                            this.highlightedIndex = 0
                        } else {
                            this.highlightedIndex++
                        }
                    }
                    didUpdateHighlight = true
                } else if (matches && !didUpdateHighlight) {
                    this.highlightedIndex++
                }
            }
        })

        this.oldValue = q
        this.updateHighlight()
        if (!event.detail.dontSelectOnly) {
            this.debouncedSelectOnly()
        }
    }

    highlightFirst() {
        this.highlightedIndex = 0
        this.updateHighlight()
    }

    updateHighlight() {
        this.visibleOptions().forEach((c, i) => {
            const isHighlighted = i === this.highlightedIndex
            c.classList.toggle('highlighted', isHighlighted)
            if (isHighlighted) {
                c.scrollIntoView({behavior: 'auto', block: 'nearest'})
            }
        })
    }

    refreshVisibleOptions() {
        this.visibleOptions(true)
    }

    visibleOptions(isRefresh = false) {
        const wasInError = this.inputTarget.classList.contains('has-error')
        let isInError = false
        const options = this.optionTargets.filter(c => !(c.classList.contains('d-none') || c.classList.contains('hidden-option')))
        if (this.highlightedIndex > options.length - 1) {
            // reset
            const allowedOptions = this.optionTargets.filter(c => !c.classList.contains('hidden-option'))
            this.highlightedIndex = Math.max(allowedOptions.length - 1, 0)

            if (isRefresh) {
                this.optionTargets.forEach(opt => {
                    opt.classList.remove('d-none')
                })
                this.inputTarget.value = allowedOptions[this.highlightedIndex].textContent.trim()
                this.inputTarget.dispatchEvent(new CustomEvent('input', { detail: { dontSelectOnly: true } }))
            } else if (options.length === 0) {
                this.inputTarget.select()
                isInError = true
            }
        }
        this.inputTarget.classList.toggle('has-error', isInError)
        if (wasInError && !isInError) {
            const inputLength = this.inputTarget.value.length
            this.inputTarget.selectionStart = inputLength
            this.inputTarget.selectionEnd = inputLength
        }
        return options
    }

    highlightUp(event) {
        event.preventDefault()
        event.stopPropagation()
        this.highlightedIndex = Math.max(0, this.highlightedIndex - 1)
        this.updateHighlight()
    }

    highlightDown(event) {
        event.preventDefault()
        event.stopPropagation()
        this.highlightedIndex = Math.min(this.visibleOptions().length - 1, this.highlightedIndex + 1)
        this.updateHighlight()
    }

    getSelectedOption() {
        const options = this.visibleOptions()
        if (options.length <= this.highlightedIndex) {
            return null
        } else {
            return options[this.highlightedIndex]
        }
    }

    selectHighlighted(event = null) {
        if (event) {
            event.stopPropagation()
            event.preventDefault()
        }

        const highlighted = this.getSelectedOption()
        if (highlighted) {
            highlighted.click()
        }
        this.blur()
    }

    toggle(event) {
        if (this.dropdownMenuTarget.classList.contains('show')) {
            event.stopImmediatePropagation()
            event.preventDefault()
            this.blur()
        } else {
            this.show()
        }
    }

    show() {
        if (this.dropdownMenuTarget.classList.contains('show')) {
            return
        }

        this.highlightFirst()
        this.inputTarget.focus()
        this.inputTarget.select()
        this.dropdownMenuTarget.classList.add('show')
    }

    blur(event, force) {
        if (this.isMouseDown && !force) {
            return
        }

        this.dropdownMenuTarget.classList.remove('show')
    }

    selectOnly() {
        if (this.visibleOptions().length === 1) {
            this.selectHighlighted()
        }
    }

    onClick(event) {
        const el = event.target

        if (this.element.contains(el) || el === this.element) {
            if (el.classList.contains('.searchable-dropdown-close-btn')) {
                this.toggle()
                return
            }

            if (!(el.classList.contains('dropdown-item'))) {
                this.show()
            }
            return
        }
        if (el.classList.contains('dropdown-item')) {
            return
        }
        this.blur(event, true)
    }

    mousedown() {
        this.isMouseDown = true
    }

    mouseup() {
        this.isMouseDown = false
    }
}
