import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import {Controller} from "@hotwired/stimulus"
import moment from "moment";

function lastThirtyDays() {
    return [moment().subtract(29, 'days').toDate(), new Date()]
}

function thisMonth() {
    return [moment().startOf('month').toDate(), new Date()]
}

function quartersAgoContent(quartersAgo, quarterName) {
    const date = moment().subtract(quartersAgo, 'quarters')
    return `${quarterName} (Q${date.quarter()} ${date.year()})`
}

function quartersAgoDates(quartersAgo) {
    const firstDate = moment().subtract(quartersAgo, 'quarters').startOf('quarter').toDate()
    const lastDate = moment().subtract(quartersAgo, 'quarters').endOf('quarter').toDate()
    return [firstDate, lastDate]
}

function quartersAgoButton(quartersAgo, quarterName) {
    return {content: quartersAgoContent(quartersAgo, quarterName), dates: quartersAgoDates(quartersAgo)}
}

function thisYear() {
    return [moment().startOf('year').toDate(), new Date()]
}

function allTime(earliestTransactionDate) {
    return [earliestTransactionDate, new Date()]
}

export default class extends Controller {
    static targets = ['input']

    disconnect() {
        this.inputTarget.removeEventListener('change', this.onChangeHandler)
        this.adp.destroy()
    }

    connect() {
        this.labelFromDate = {}

        this.onChangeHandler = (event) => {
            event.preventDefault()
            event.stopPropagation()
            if (this.adp) {
                let dates = this.inputTarget.value
                    .replace(/0(\d)\//g, '$1/')
                    .split(' - ')
                    .map(d => moment(d, 'M/D/YY').toISOString())
                this.adp.selectDate(dates)
            }
        }

        this.inputTarget.addEventListener('change', this.onChangeHandler, { capture: true })

        const data = this.inputTarget.dataset
        const position = data.adpPosition || 'top right'
        const isRange = data.adpRange === 'true'
        const startDate = data.startDate
        const endDate = data.endDate
        const earliestTransactionDate = data.earliestTransactionDate

        let options = {
            inline: false,
            locale: localeEn,
            visible: false,
            autoClose: true,
            position: position,
            dateFormat: isRange ? 'M/dd/yy' : 'yyyy-MM-dd',
            range: isRange,
            toggleSelected: !isRange, // allows start and end of range to be same date - https://github.com/t1m0n/air-datepicker/issues/557
            multipleDatesSeparator: ' - ',
            selectedDates: isRange ? [startDate, endDate] : '',
            onSelect: this.onSelect.bind(this),
        }

        if (isRange) {
            options.buttons = []
            const buttons = [
                {content: "Last 30 days", dates: lastThirtyDays()},
                {content: "This month", dates: thisMonth()},
                quartersAgoButton(0, 'This quarter'),
                quartersAgoButton(1, 'Last quarter'),
                quartersAgoButton(2, '2 quarters ago'),
                {content: "This year", dates: thisYear()}
            ]

            if (earliestTransactionDate) {
                buttons.push({content: "All time", dates: allTime(earliestTransactionDate)})
            }

            buttons.forEach(b => {
                this.addButton(options.buttons, b)
            })
        }

        this.adp = new AirDatepicker(this.inputTarget, options)
    }

    addButton(buttons, {content, dates}) {
        dates = dates.map(d => moment(d).format('M/DD/YY'))
        this.labelFromDate[dates] = content
        buttons.push({content, onClick: dp => dp.selectDate(dates)})
    }

    toggleVisibility() {
        if (this.adp.visible) {
            this.adp.hide()
        } else {
            this.adp.show()
        }
    }

    clear() {
        this.adp.clear()
    }

    onSelect(event) {
        if (Array.isArray(event.formattedDate)) {
            this.inputTarget.dataset.selectedOption = this.labelFromDate[event.formattedDate] || event.formattedDate.join(' - ')
        }
        const pickerStillOpen =
            document
                .querySelector('.air-datepicker-global-container .air-datepicker')
                ?.classList
                ?.contains("-active-")

        if (!pickerStillOpen) {
            this.inputTarget.dispatchEvent(new Event('adp-select'))
        }
    }
}