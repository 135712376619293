import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['check', 'label']

    update(event) {
        const value = event.target.value
        this.checkTargets.forEach(t => t.classList.toggle('d-none', t.dataset.value !== value))
        this.labelTargets.forEach(l => l.classList.toggle('checked', l.dataset.value === value))
    }
}