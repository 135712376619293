import {Controller} from "@hotwired/stimulus"
import {debounce} from './helpers'

export default class extends Controller {
    static values = {
        removeCurrencySymbol: Boolean,
        currencyCode: String
    }

    stripValue(value) {
        if (!value) {
            return ''
        }
        return value.replace(/[^\-0-9.]/g, '')
    }

    removeCurrencySymbol(value) {
        const currencySymbol = this.formatter.formatToParts(value)[0]['value']
        return value.replace(currencySymbol, '')
    }

    convertToMoney() {
        let stripped = this.stripValue(this.getValue())
        if (stripped === '') {
            return
        }

        if (this.oldFormattedValue) {
            if (stripped.startsWith(this.oldFormattedValue)) {
                // they just kept typing after we auto-formatted
                stripped = this.oldValue + stripped.replace(this.oldFormattedValue, '')
            }

            // e.g., 41.00.23 -> 41.23
            stripped = stripped.replace(/\.00(?=.)/g, '')
        }

        let formatted = this.formatter.format(stripped)

        if (this.removeCurrencySymbolValue) {
            formatted = this.removeCurrencySymbol(formatted)
        }

        this.setValue(formatted)
        this.oldValue = stripped
        this.oldFormattedValue = formatted
    }

    getValue() {
        if (this.element.tagName === 'INPUT') {
            return this.element.value
        }

        return this.element.textContent
    }

    setValue(value) {
        if (this.element.tagName === 'INPUT') {
            this.element.value = value
        }

        this.element.textContent = value
    }

    debouncedConvertToMoney() {

    }

    connect() {
        this.oldValue = this.getValue()
        this.oldFormattedValue = null

        this.formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: this.currencyCodeValue,
        })
        this.debouncedConvertToMoney = debounce(this.convertToMoney.bind(this), 1000)
        this.convertToMoney()
    }
}