import {Controller} from "@hotwired/stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  disconnect() {
    if (this.element.closest('.sortable-ghost')) {
      return
    }
    this.sortable?.destroy()
  }
  
  connect() {
    if (this.element.closest('.sortable-ghost')) {
      return
    }

    this.options = {
      handle: '.fa-grip-dots-vertical',
      draggable: '.draggable',
      invertSwap: true,
      forceFallback: true,
      onChoose: () => {
        document.querySelector('body').classList.add('grabbing')
      },
      onUnchoose: () => {
        document.querySelector('body').classList.remove('grabbing')
      },
      onStart: (event) => {
        this.element.classList.add('sorting')
        document.querySelector('body').classList.add('grabbing')
      },
      onEnd: (event) => {
        document.querySelector('body').classList.remove('grabbing')
        this.element.classList.remove('sorting')
        this.element.dispatchEvent(new CustomEvent('onSorted', {detail: event}))
      }
    }

    const group = this.element.dataset.group
    if (group) this.options.group = group

    const draggableSelector = this.element.dataset.draggableSelector
    if (draggableSelector) {
      this.options.draggable = draggableSelector
    }

    const handleSelector = this.element.dataset.handleSelector
    if (handleSelector) {
      this.options.handle = handleSelector
    }

    this.sortable = Sortable.create(this.element, this.options)
  }
}
