import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['categoryFilter', 'category', 'isInflow']

    connect() {
        this.categoryTargets.forEach(c => {
            if (c.closest('.d-none')) {
                c.value = ''
            }
        })

        this.onIsInflowChanged()
    }

    onIsInflowChanged() {
        const isInflow = this.isInflowTargets.filter(t => t.checked)[0].value.toString() === 'true'
        const direction = isInflow ? 'inflow' : 'outflow'
        this.categoryFilterTargets.forEach(f => {
            const matches = f.dataset.direction === direction;
            f.classList.toggle('d-none', !matches)

            const s = f.querySelector('[data-category-filter-target="select"]')
            if (matches) {
                f.classList.remove('d-none')
                s.value = s.dataset.selected || ''
                s.name = 'expected_transaction[category_id]'
                if (s.value === '') {
                    s.dispatchEvent(new Event('selectOnlyOption'))
                }
            } else {
                f.classList.add('d-none')
                s.name = 'temp'
                if (s.value) {
                    s.dataset.selected = s.value
                    s.value = ''
                }
            }
        })
    }
}