import {Controller} from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
    static targets = ["canvas"]

    disconnect() {
        this.chart.destroy()
    }

    connect() {
        const utilization = parseInt(this.element.dataset.utilization)
        const empty = 100 - utilization

        this.chart = new Chart(this.canvasTarget, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [utilization, empty],
                    backgroundColor: ['#49b3ff', 'white']
                }]
            },
            options: {
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                animation: {
                    animateRotate: false
                },
                hover: {
                    mode: null
                },
                interaction: {
                    mode: 'point'
                },
                plugins: {
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        display: false
                    }
                }
            }
        })
    }
}