import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['teamCodeVisible', 'teamCodeHidden', 'visible', 'hidden', 'copyNotify']

    connect() {
        this.hidden = true
        this.update()
    }

    hide() {
        this.hidden = true
        this.update()
    }

    reveal() {
        this.hidden = false
        this.update()
    }

    copy() {
        navigator.clipboard.writeText(this.teamCodeVisibleTarget.innerText)
        this.copyNotifyTarget.click()
    }

    update() {
        this.visibleTarget.classList.toggle('d-none', !this.hidden)
        this.teamCodeVisibleTarget.classList.toggle('d-none', this.hidden)
        this.hiddenTarget.classList.toggle('d-none', this.hidden)
        this.teamCodeHiddenTarget.classList.toggle('d-none', !this.hidden)
    }
}