import {Controller} from "@hotwired/stimulus"
import {loadScript} from './helpers'

export default class extends Controller {
    async connect() {
        await loadScript(`https://www.google.com/recaptcha/enterprise.js?render=${this.element.dataset.recaptchaSiteKey}`)
    }

    async submit(event) {
        event.preventDefault()
        const action = event.target.dataset.recaptchaAction
        const recaptcha = JSON.stringify(await this.recaptcha(action))
        event.detail.fetchOptions.body.append('recaptcha', recaptcha)
        event.detail.resume()
    }

    recaptcha(action) {
        return new Promise((resolve, reject) => {
            grecaptcha.enterprise.ready(async () => {
                try {
                    const token = await grecaptcha.enterprise.execute(
                        this.element.dataset.recaptchaSiteKey, {action}
                    );
                    resolve({token, action})
                } catch (error) {
                    console.log('error', error)
                    reject(error)
                }
            })
        })
    }
}