import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    updateUrl(event) {
        if (event.detail.success) {
            let url = new URL(window.location)

            let form = event.target
            let formData = new FormData(form)

            for (let [key, value] of formData.entries()) {
                url.searchParams.set(key, value)
            }

            window.history.pushState({}, "", url)
        }
    }
}