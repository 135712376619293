import {Controller} from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  async onAssetGroupSorted(event) {
    const sort_order = event.detail.newDraggableIndex
    const id = parseInt(event.detail.item.dataset.assetGroupId)
    const body = JSON.stringify({ sort_order })
    await post(`/asset_groups/${id}/reorder`, { body })
  }
}
