import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submenu" ]

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.submenuTarget.style.display = this.submenuTarget.style.display === 'block' ? 'none' : 'block';
  }

  show(event) {
    event.preventDefault();
    this.submenuTarget.style.display = 'block';
  }

  hide(event) {
    event.preventDefault();
    this.submenuTarget.style.display = 'none';
  }
}