import {Controller} from "@hotwired/stimulus"
import {destroy, post} from "@rails/request.js";
import {Turbo} from "@hotwired/turbo-rails"
import {Modal} from 'bootstrap'

const ONE_MINUTE = 60 * 1000

export default class extends Controller {
    static values = {timeout: Number}

    connect() {
        if (!this.timeoutValue) {
            return
        }

        this.logOutTimeout = null
        this.modal = null
        this.modalTimeout = null
        this.modal = new Modal(this.element)
        this.restartTimeout()
    }

    disconnect() {
        clearTimeout(this.modalTimeout)
        clearTimeout(this.logOutTimeout)

        if (this.modal) {
            this.modal.dispose()
            this.modal = null
        }
    }

    restartTimeout() {
        clearTimeout(this.modalTimeout)
        clearTimeout(this.logOutTimeout)

        this.modalTimeout = setTimeout(() => {
            this.modal.show()
            clearTimeout(this.logOutTimeout)
            this.logOutTimeout = setTimeout(this.confirmTimeout.bind(this), ONE_MINUTE)
        }, this.timeoutValue - ONE_MINUTE)
    }

    async confirmTimeout() {
        await destroy('/users/sign_out')
        Turbo.visit('/users/sign_in?automatic_logout=true')
    }

    async preventTimeout() {
        await post('/stay-logged-in')
        this.modal.hide()
        this.restartTimeout()
    }
}