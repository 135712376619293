import {Controller} from "@hotwired/stimulus"
import {debounce} from './helpers'

export default class extends Controller {
    initialize() {
        this.debouncedSubmit = debounce(this.submit.bind(this), 500)
    }

    debouncedSubmit() {
    }

    async submit(event, selectedOption = null) {
        const form = event.target.closest('form');
        const dateRangeLabel = form.querySelector('[name=date_range_label]');
        if (dateRangeLabel) {
            dateRangeLabel.value = selectedOption || ''
        }
        form.requestSubmit()
    }

    async search(event) {
        const selectedOption = event.target.dataset.selectedOption
        if (event.target.value.match(/\d\d?\/\d\d?\/\d\d? - \d\d?\/\d\d?\/\d\d?/)) {
            event.target.classList.remove('has-error')
            document.getElementById('date-range-error-message').classList.add('d-none')
            await this.submit(event, selectedOption)
        } else {
            event.target.classList.add('has-error')
            document.getElementById('date-range-error-message').classList.remove('d-none')
        }
    }
}
