import {Controller} from "@hotwired/stimulus"
import {Modal} from 'bootstrap'

export default class extends Controller {
    static targets = [
        'src',
        'croppedSrc',
        'previewSrc',
        'crop',
        'upload',
        'remove',
        'cropModal',
        'croppieImage'
    ]

    connect() {
        this.modal = null
    }

    disconnect() {
        if (this.modal) {
            this.modal.dispose()
        }
    }

    updatePreview() {
        this.readAvatar((src) => {
          this.previewSrcTarget.src = src
        })

        this.cropTarget.style.display = 'block'
        this.removeTarget.style.display = 'block'
        this.uploadTarget.textContent = 'Replace'
    }

    readAvatar(onload) {
        const file = this.srcTarget.files[0]
        const reader = new FileReader()

        reader.onload = () => {
            onload(reader.result)
        }

        reader.readAsDataURL(file)
    }

    startCrop() {
        if (!this.modal) {
            this.modal = new Modal(this.cropModalTarget)
        }
        this.croppieImageTarget.src = this.previewSrcTarget.src
        this.modal.show()
    }

    showModal() {
        this.croppie = new Croppie(this.croppieImageTarget, {
            boundary: {height: 256, width: 256},
            viewport: {height: 128, width: 128, type: 'circle'}
        })

        // must do as an interval since croppie doesn't give us an event when it is ready to go.
        let interval = setInterval(() => {
            const zoomer = this.croppie.elements.zoomer
            if (zoomer) {
                const min = zoomer.min
                this.croppie.setZoom(min)
                if (parseInt(min) === parseInt(this.croppie.get().zoom)) {
                    clearInterval(interval)
                }
            }
        }, 10)
    }

    hideModal() {
        if (this.croppie) {
            this.croppie.destroy()
            this.croppie = null
        }
    }

    submitCrop() {
        this.croppie.result({type: 'base64', circle: false}).then(result => {
            this.croppedSrcTarget.value = result
            this.previewSrcTarget.src = result
            if (this.croppie) {
                this.croppie.destroy()
                this.croppie = null
            }
            this.modal.hide()
        })
    }
}