import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.closeOnEscape = this.closeOnEscape.bind(this)
        this.closeOnOutsideClick = this.closeOnOutsideClick.bind(this)
        document.addEventListener('keydown', this.closeOnEscape)
        document.addEventListener('click', this.closeOnOutsideClick)
    }

    disconnect() {
        document.removeEventListener('keydown', this.closeOnEscape)
        document.removeEventListener('click', this.closeOnOutsideClick)
    }

    closeOnEscape(event) {
        if (event.key === "Escape") {
            this.closeAll()
        }
    }

    closeOnOutsideClick(event) {
        if (!event.target.closest('.cal-day-parent')) {
            this.closeAll()
        }
    }

    toggleTooltip(event) {
        const day = event.currentTarget;
        day.classList.toggle('cal-expanded')
        const target = day.dataset.tooltipTarget;
        document.getElementById(target).classList.toggle('d-none')
        this.closeAllExcept(target)
    }

    closeAll() {
        this.closeAllExcept('just-close-all-of-them')
    }

    closeAllExcept(except) {
        document.querySelectorAll(`.cal-day:not([data-tooltip-target="${except}"])`).forEach(d => d.classList.remove('cal-expanded'))
        document.querySelectorAll(`.cal-day-tooltip-padding:not(#${except})`).forEach(t => t.classList.add('d-none'))
    }
}