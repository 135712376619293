import {destroy} from '@rails/request.js'
import {Controller} from "@hotwired/stimulus"
import {Modal} from "bootstrap"

export default class extends Controller {
  disconnect() {
    if (this.modal) {
      this.modal.dispose()
    }
  }

  connect() {
    if (this.element.querySelector('.modal-body')) {
      this.modal = new Modal(this.element)
      this.modal.show()
    }
  }

  async remove_and_relink(event) {
    const data = event.target.dataset
    const url = data.url
    const institutionId = data.institutionId
    const asset_group_id = data.assetGroupId
    this.modal.hide()
    const response = await destroy(url)

    document
      .querySelectorAll(`.account-row[data-institution-id="${institutionId}"]`)
      .forEach(r => r.remove())

    document
      .querySelectorAll(`.asset-row-form[data-institution-id="${institutionId}"]`)
      .forEach(r => r.remove())

    if (response.ok) {
      let selector = '.add-account-button'
      if (asset_group_id) {
        selector = `[data-link-asset-group-id-value="${asset_group_id}"] ${selector}`
      }
      document.querySelector(selector).click()
    }
    this.element.innerHTML = ''
  }

  async relink(event) {
    this.modal.hide()
    const data = event.target.dataset
    const assetGroupId = data.assetGroupId
    document
      .getElementById(data.updateAccountsButtonId)
      .dispatchEvent(new CustomEvent('click', { detail: { assetGroupId } }))

    this.element.innerHTML = ''
  }
}
