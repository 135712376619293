import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "content",
    "icon",
    "blurb",
    "plaidName"
  ]

  toggle() {
    this.contentTarget.classList.toggle("d-none")
    this.iconTarget.classList.toggle("fa-up-right-and-down-left-from-center")
    this.iconTarget.classList.toggle("fa-down-left-and-up-right-to-center")
  }

  updateInfoBlurb(event) {
    if (!this.hasPlaidNameTarget) {
      return
    }
    let plaidName = this.plaidNameTarget.textContent
    const newName = event.target.value

    if (newName === "") {
      this.blurbTarget.textContent = plaidName
    } else {
      this.blurbTarget.textContent = `"${newName}"`
    }
  }

  moneyGroupDirectionSelected(event) {
    const selectedDirection = event.target.value
    const url = `/categories/money_group_ids?money_group_direction=${selectedDirection}`


    get(url, {
      headers: {Accept: 'text/vnd.turbo-stream.html'}
    })
    .then(() => {
      document.getElementById("money-group-container").classList.toggle("d-none", selectedDirection !== "outflow")
      document.getElementById("display-in-mf").classList.toggle("d-none", selectedDirection === "transfer")
    })
  }

}
