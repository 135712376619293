import {Controller} from "@hotwired/stimulus"
import {post, patch} from "@rails/request.js";

export default class extends Controller {
  static targets = [
    'nameInput',
    'valueInput',
    'assetGroupIdInput',
    'form',
    'submit',
    'cancel',
    'addNameBtn',
    'addValueBtn',
    'preSaveName',
    'postSaveName',
    'postSaveNameDisplay',
    'preSaveValue',
    'postSaveValue',
    'postSaveValueDisplay',
    'assetLinkOptions',
  ]

  async initialize() {
  }

  async connect() {
  }

  cancel() {
    this.hideForm()
  }

  resetForm() {
    if (this.hasNameInputTarget) {
      this.nameInputTarget.value = this.nameInputTarget.dataset.originalValue || ''
    }
    if (this.hasValueInputTarget) {
      this.valueInputTarget.value = this.valueInputTarget.dataset.originalValue || ''
    }
  }

  hideForm(isCreate = false) {
    // We need a slight delay to prevent a weird bug.
    // Basically, if we change the visibility of these elements immediately,
    // the tab event we are handling will shift focus to one of these newly-visible elements,
    // that focus event triggers the event handler that makes the input editable.
    // So, by waiting briefly to show these elements, we allow focus to shift to the
    // correct next element.
    setTimeout(() => {
      this.resetForm()

      this.formTarget.classList.remove('active')

      if (this.hasAddNameBtnTarget) {
        this.addNameBtnTarget.classList.remove('d-none')
      }

      if (this.hasAddValueBtnTarget) {
        this.addValueBtnTarget.classList.remove('d-none')
      }

      if (isCreate) {
        this.addName()
      }
    }, 0)
  }

  showNameInput(force = false) {
    let showInput = force
    if (!showInput) {
      showInput = this.hasNameInputTarget && this.nameInputTarget.value !== ''
    }

    if (this.hasAddNameBtnTarget) {
      this.addNameBtnTarget.classList.toggle('d-none', showInput)
    }
    if (this.hasNameInputTarget) {
      this.nameInputTarget.parentElement.classList.toggle('d-none', !showInput)
      if (this.hasAssetLinkOptionsTarget) {
        this.assetLinkOptionsTarget.classList.toggle('d-none', !force)
      }
    }
  }

  showValueInput(force = false) {
    let showInput = force
    if (!showInput) {
      showInput = this.hasValueInputTarget && this.valueInputTarget.value !== ''
    }

    if (this.hasAddValueBtnTarget) {
      this.addValueBtnTarget.classList.toggle('d-none', showInput)
    }
    if (this.hasValueInputTarget) {
      this.valueInputTarget.parentElement.classList.toggle('d-none', !showInput)
    }
  }

  addName(event) {
    if (event && event.key !== 'Tab') {
      event?.preventDefault()
    }

    this.formTarget.classList.add('active')
    this.showNameInput(true)
    this.showValueInput()
    if (this.hasNameInputTarget) {
      this.nameInputTarget.focus()
      this.nameInputTarget.select()
    }
  }

  addValue(event) {
    if (event && event.key !== 'Tab') {
      event?.preventDefault()
    }
    this.formTarget.classList.add('active')
    this.showNameInput()
    this.showValueInput(true)
    if (this.hasValueInputTarget) {
      this.valueInputTarget.focus()
      this.valueInputTarget.select()
    }
  }

  async submit(event) {
    let nameHasChanges = false
    let valueHasChanges = false
    let name = ''
    let value = ''
    if (this.hasNameInputTarget) {
      name = this.nameInputTarget.value || ''
      nameHasChanges = name !== (this.nameInputTarget.dataset.originalValue || '')
    }
    if (this.hasValueInputTarget) {
      value = this.valueInputTarget.value || ''
      valueHasChanges = value !== (this.valueInputTarget.dataset.originalValue || '')
    }

    const noChanges = !nameHasChanges && !valueHasChanges
    if (noChanges) {
      this.hideForm()
      return
    }

    const asset_group_id = this.assetGroupIdInputTarget.value

    const form = this.formTarget
    const body = {asset: {asset_group_id, name, value}}

    const isCreate = this.isCreate();
    if (isCreate) {
      const temporaryAssetRow = this.element.parentElement.querySelector('.temporary-asset-row');
      setTimeout(() => {
        temporaryAssetRow.dispatchEvent(new CustomEvent('update', { detail: { name, value } }))
      }, 0)
    } else {
      if (nameHasChanges) {
        this.preSaveNameTargets.forEach(t => t.classList.add('d-none'))
        this.postSaveNameDisplayTarget.textContent = name
        this.postSaveNameTarget.classList.remove('d-none')
      }

      if (valueHasChanges) {
        this.preSaveValueTargets.forEach(t => t.classList.add('d-none'))
        this.postSaveValueDisplayTarget.textContent = value
        this.postSaveValueDisplayTarget.dispatchEvent(new CustomEvent('change'))
        this.postSaveValueTarget.classList.remove('d-none')
      }
    }

    this.hideForm(isCreate)

    const method = isCreate ? post : patch
    method(form.action, {
      body, headers: {Accept: 'text/vnd.turbo-stream.html'}
    })
  }

  async onEnter(event) {
    event.preventDefault()
    this.submit(event)
  }

  onClick(event) {
    const el = event.target

    if (!(this.element.contains(el) || el === this.element)) {
      this.submit()
    }
  }

  isCreate() {
    return this.element.id.includes('new_asset_row_form')
  }
}