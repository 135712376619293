import {Controller} from "@hotwired/stimulus"
import {Modal} from 'bootstrap'

export default class extends Controller {
    static targets = ['nameNearMatchConfirmationModal', 'inviteeFirstName', 'firstName']

    connect() {
        this.modal = null
        this.onUpdateFirstName()
        this.showNameNearMatchConfirmation()
    }

    disconnect() {
        if (this.modal) {
            this.modal.dispose()
            this.modal = null
        }
    }

    showNameNearMatchConfirmation() {
        if (this.hasNameNearMatchConfirmationModalTarget) {
            this.modal = new Modal(this.nameNearMatchConfirmationModalTarget)
            this.modal.show()
        }
    }

    formatTeamCode(event) {
        event.target.value = event.target.value.trim().replace(/[^a-z0-9]/gi, '').toUpperCase()
    }

    onUpdateFirstName() {
        const firstName = this.firstNameTarget.value.trim()
        this.inviteeFirstNameTargets.forEach(t => {
            if (firstName) {
                t.textContent = `${firstName}${t.dataset.notDefaultSuffix || ''}`
            } else {
                t.textContent = t.dataset.default
            }
        })
    }
}