import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["fullName", "nickname"]
    
    connect() {
        this.oldFullName = this.fullNameTarget.value
        this.onUpdate()
    }
    
    onUpdate() {
        if (this.isNicknameCustom) {
            return
        }

        let fullNameValue = this.fullNameTarget.value
        let nickNameValue = this.nicknameTarget.value

        let fullNameShort = fullNameValue.split(' ')[0]
        let oldFullNameShort = this.oldFullName.split(' ')[0]

        if (nickNameValue === oldFullNameShort || nickNameValue === '') {
            this.nicknameTarget.value = fullNameShort
        } else {
            this.isNicknameCustom = true
        }

        this.oldFullName = fullNameValue
    }
}