import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    markPending(event) {
        if (event.target.classList.contains('pending')) {
            return
        }

        event.target.classList.add('pending')
    }
}
